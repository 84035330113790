<template>
  <v-form @submit.prevent="handleSubmitForm()">
    <ValidationObserver ref="form">
      <v-card>
        <v-card-title>
          {{ __('Gestores') }}
          <v-spacer></v-spacer>
          <v-btn @click="handleCloseForm()" icon :disabled="loading">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            name="user_id"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              v-if="form.user_id && manager_id"
              :label="__('Utilizador')"
              :value="form.user.name"
              readonly
              :hint="__('O utilizador não pode ser alterado')"
              persistent-hint
            ></v-text-field>
            <v-autocomplete
              v-else
              :disabled="loading || loading_user"
              name="user_id"
              :label="__('Utilizador')"
              :items="users"
              item-text="name"
              item-value="id"
              :loading="loading_user"
              v-model="form.user_id"
              :error-messages="errors"
            >
              <template v-slot:append-outer>
                <v-btn @click="getUsers()" :disabled="loading_user" icon>
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
          </ValidationProvider>
          <ValidationProvider
            name="lead_type_id"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              :label="__('Tipo de serviço')"
              v-model="form.lead_type_id"
              :items="lead_types"
              :disabled="loading"
              item-text="description"
              item-value="id"
              name="lead_type_id"
              :error-messages="errors"
            ></v-select>
          </ValidationProvider>

          <ValidationProvider name="team_id" rules="" v-slot="{ errors }">
            <v-select
              :label="__('Equipe', 'unities')" 
              item-text="name"
              item-value="id"
              name="team_id"
              :error-messages="errors"
              :items="teams"
              :disabled="loading"
              v-model="form.team_id"
              clearable
            ></v-select>
          </ValidationProvider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="handleCloseForm()" text :disabled="loading">
            {{ __('Cancelar') }}
          </v-btn>
          <v-btn type="submit" color="primary" :loading="loading">
            {{ __('Salvar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import { index as indexUsers } from "@/services/users";
import {
  store as storeManager,
  update as updateManager,
  show as showManager,
} from "@/services/service_managers";
import { index as indexTeams } from "@/services/teams";
import i18n from '@/mixins/i18n';

export default {
  name: "manager-form",
  props: ["manager_id"],
  mixins: [i18n],
  data: () => ({
    i18n_prefix: 'managers',
    loading: false,
    loading_user: false,
    users: [],
    form: {},
    teams: [],
  }),
  methods: {
    async getTeams() {
      const response = await indexTeams();
      this.teams = response.data;
    },
    handleCloseForm() {
      this.resetForm();
      this.$emit("close");
    },
    resetForm() {
      this.form = {};
      this.$refs.form.reset();
    },
    async handleSubmitForm() {
      const validation = await this.$refs.form.validate();

      if (validation) {
        this.loading = true;
        try {
          if (this.form.id) {
            await updateManager(
              {
                ...this.form,
                team_id: this.form.team_id || null,
              },
              this.form.id
            );
            this.$store.commit("sendMessage", {
              text: this.__('Registro atualizado com sucesso!'),
            });
          } else {
            await storeManager({
              ...this.form,
            });
            this.$store.commit("sendMessage", {
              text: this.__('Registrado com sucesso!'),
            });
          }

          this.resetForm();
          this.$emit("submit");
        } catch (error) {
          if (error.response) {
            switch (error.response.status) {
              case 422:
                this.$refs.form.setErrors(error.response.data.errors);
                this.$store.commit("sendMessage", {
                  text: error.response.data.message,
                  color: "red",
                });
                break;
            }
          } else {
            this.$store.commit("sendMessage", {
              text: this.__('Não foi possível salvar dados!'),
              color: "red",
            });
          }
        } finally {
          this.loading = false;
        }
      }
    },
    async getUsers() {
      this.loading_user = true;
      try {
        const response = await indexUsers({ list: true });
        this.users = response;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.__('Não foi possível carregar os usuários!'),
          color: "red",
        });
      } finally {
        this.loading_user = false;
      }
    },
    async getManager() {
      this.loading = true;
      try {
        const response = await showManager(this.manager_id);
        this.form = response;
      } catch (error) {
        console.error(error);
        this.$store.commit("sendMessage", {
          text: this.__('Não foi possível carregar o gestor!'),
          color: "red",
        });
        this.$emit("cancel");
      } finally {
        this.loading = false;
      }
    },
    ...mapActions("lead_types", ["loadTypes"]),
  },
  computed: {
    lead_types() {
      return this.$store.getters["lead_types/getLeadTypes"];
    },
  },
  watch: {
    manager_id: function (value) {
      if (value == null) return;

      this.getManager();
    },
  },
  created() {
    this.getUsers();
    this.loadTypes();
    this.getTeams();

    if (this.manager_id) this.getManager();
  },
};
</script>
