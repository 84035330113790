<template>
  <container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            {{ __('Gestores') }}
            <v-btn @click="getManagers()" icon>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="filter"
                  :label="__('Pesquisar gestor')"
                  prepend-inner-icon="mdi-magnify"
                  @keyup.enter="getManagers"
                  :disable="loading"
                  clearable
                  single-line
                  solo
                ></v-text-field>
              </v-col>

              <v-col class="text-right">
                <scope-provider scope="service_managers.add">
                  <v-btn @click="handleOpenDialog()" color="primary">
                    <v-icon left>mdi-plus</v-icon
                    >{{ __('Novo gestor') }}
                  </v-btn>
                </scope-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="4" md="3" lg="2" xl="1">
                <v-select 
                  v-model="status"
                  @change="getManagers()"
                  label="Status"
                  hide-details
                  outlined
                  dense
                  :items="[
                    {
                      text: __('Todos'),
                      value: 'all'
                    },
                    {
                      text: __('Ativos'),
                      value: 'active'
                    },
                    {
                      text: __('Bloqueados'),
                      value: 'block'
                    },
                  ]"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          
          <v-data-table
            :headers="headers"
            :items="items"
            :disabled="loading"
            :loading="loading"
            :loading-text="__('A carregar')"
            :item-class="item => !item.is_active ? 'red--text' : ''"
          >
            <template v-slot:header.name>
              {{ __('Nome') }}
            </template>

            <template v-slot:header.lead_type_description>
              {{ __('Serviços') }}
            </template>

            <template v-slot:header.is_active>
              {{ __('Status') }}
            </template>

            <template v-slot:header.created_at>
              {{ __('Data de registro') }}
            </template>

            <template v-slot:header.updated_at>
              {{ __('Última alteração') }}
            </template>

            <template v-slot:item.name="{ item }">
              <span>
                <user-avatar
                  :avatar_url="item.avatar.url"
                  :user_name="item.avatar.name"
                  size="30"
                  :user_id="item.avatar.id"
                  class="mr-2"
                />
                {{ item.name }}
                <v-menu offset-x transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon v-on="on" v-bind="attrs">
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <scope-provider scope="service_managers.edit">
                      <v-list-item @click="handleEditManager(item.id)">
                        <v-icon left size="20">mdi-pencil</v-icon>
                        <v-list-item-title>
                          {{ __('Alterar') }}
                        </v-list-item-title>
                      </v-list-item>
                    </scope-provider>
                    <v-divider></v-divider>

                    <scope-provider v-if="item.is_active" scope="service_managers.block">
                      <v-list-item @click="blockManager(item)" link class="red--text">
                        <v-icon left size="20" color="red">mdi-close</v-icon>
                        <v-list-item-title>
                          {{ __('Bloquear') }}
                        </v-list-item-title>
                      </v-list-item>
                    </scope-provider>
                    <scope-provider v-else scope="service_managers.restore">
                      <v-list-item @click="restoreManager(item)" link>
                        <v-icon left size="20">mdi-backup-restore</v-icon>
                        <v-list-item-title>
                          {{ __('Restaurar') }}
                        </v-list-item-title>
                      </v-list-item>
                    </scope-provider>
                  </v-list>
                </v-menu>
              </span>
            </template>

            <template v-slot:item.lead_type_description="{ item }">
              <v-chip label value small>{{ item.lead_type.description }}</v-chip>
            </template>

            <template v-slot:item.is_active="{ item }">
              {{ item.is_active ? __('Ativo') : __('Bloqueado') }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="manager_dialog" max-width="500" persistent>
      <ManagerForm
        v-model="manager"
        :title="__('Dados do gestor')"
        @submit="handleSubmitForm"
        @close="handleCloseManagerDialog"
        :manager_id="edit_manager"
      ></ManagerForm>
    </v-dialog>

    <v-dialog v-model="confirm_dialog" max-width="300" persistent>
      <v-card>
        <v-card-title>
          {{ __('Remover gestor') }}?
          <v-spacer></v-spacer>
          <v-btn @click="confirm_dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading_deleting_manager"
            text
            @click="confirm_dialog = false"
            >{{ __('Não') }}</v-btn
          >
          <v-btn
            :loading="loading_deleting_manager"
            color="red"
            dark
            @click="removeManager()"
            >{{ __('Remover') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </container>
</template>

<script>
import {
  index as indexServiceManagers,
  destroy as deleteManager,
  block as blockManager,
  restore as restoreManager,
} from "../services/service_managers";
import ManagerForm from "../components/forms/ManagerForm";
import ScopeProvider from "../components/ScopeProvider";
import UserAvatar from "@/components/UserAvatar";
import i18n from '@/mixins/i18n';

export default {
  components: { ManagerForm, ScopeProvider, UserAvatar },
  mixins: [i18n],
  data: () => ({
    i18n_prefix: 'managers',
    items: [],
    status: 'active',
    headers: [
      { text: "Nome", value: "name" },
      {
        text: "Status",
        value: "is_active",
        sortable: true,
      },
      {
        text: "Serviços",
        value: "lead_type_description",
        // TODO: Corrigir ordenação da relação
        sortable: false
      },
      {
        text: "Data registo",
        value: "created_at",
      },
      {
        text: "Última alteração",
        value: "updated_at",
      },
    ],
    filter: null,
    loading: false,
    dialog: false,
    totalItems: 0,
    options: {
      itemsPerPage: 10,
      sortBy: ["name"],
      sortDesc: []
    },
    manager_dialog: false,
    manager: {},
    loading_service_types: false,
    service_type: [],
    confirm_dialog: false,
    confirm_manager: null,
    loading_deleting_manager: false,
    edit_manager: null
  }),
  methods: {
    async blockManager(manager) {
      if(!confirm(`${this.__('Tem certeza que deseja bloquear')} ${manager.name}?`)) return;

      try {
        const { data } = await blockManager(manager.id);
        this.updateItem(data);
        this.$store.commit("alert", this.__('Salvo!'));
      } catch(error) {
        this.$store.commit("error", this.__('Não foi possível bloquear o gestor'));
        console.error(error)
      }
    },
    async restoreManager(manager) {
      if(!confirm(`${this.__('Tem certeza que deseja desbloquear')} ${manager.name}?`)) return;

      try {
        const { data } = await restoreManager(manager.id);

        this.updateItem(data);
        this.$store.commit("alert", this.__('Salvo!'));
      } catch(error) {
        this.$store.commit("error", this.__('Não foi possível restaurar o gestor'));
        console.error(error)
      }
    },
    updateItem(data) {
      this.items = this.items.map(item => {
        if(item.id !== data.id) return item;

        return {...data};
      })
    },
    handleCloseManagerDialog() {
      this.manager_dialog = false;
      this.edit_manager = null;
    },
    handleEditManager(manager_id) {
      this.edit_manager = manager_id;
      this.manager_dialog = true;
    },
    async removeManager() {
      this.loading_deleting_manager = true;

      try {
        await deleteManager(this.confirm_manager.id);
        this.items = this.items.filter(
          item => item.id !== this.confirm_manager.id
        );
        this.$store.commit("sendMessage", {
          text: this.__('Gestor excluído com sucesso')
        });
        this.confirm_dialog = false;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.__('Não foi possível excluir o gestor'),
          color: "red"
        });
      } finally {
        this.loading_deleting_manager = false;
      }
    },
    async getServiceTypes() {
      this.loading_service_types = true;
      this.service_types = [];

      try {
        this.service_types = await indexLeadTypes();
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.__('Falha ao carregar utilizadores'),
          color: "red"
        });
      } finally {
        this.loading_service_types = false;
      }
    },
    async getManagers() {
      this.loading = true;
      this.items = [];

      const response = await indexServiceManagers({
        filter: this.filter,
        status: this.status
      });

      this.items = response.data;
      this.loading = false;
      this.totalItems = response.total;
    },
    handleSubmitForm(event) {
      this.getManagers();
      this.handleCloseManagerDialog();
    },
    handleOpenDialog() {
      this.manager_dialog = true;
    },
    handleCloseDialog() {
      this.manager_dialog = false;
    },
    handleRemoveManager(manager) {
      this.confirm_dialog = true;
      this.confirm_manager = manager;
    }
  },
  watch: {
    options: {
      handler() {
        this.getManagers();
      },
      deep: true
    },
    filter: function(value) {
      if (value == null) this.getManagers();
    }
  },
  created() {
    this.getManagers();
  }
};
</script>
