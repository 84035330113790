import Vue from "vue";

export async function index(params) {
  const response = await Vue.prototype.$http.get("/users", {
    params
  });

  return response.data;
}

export async function show(id) {
  const response = await Vue.prototype.$http.get("/users/" + id);

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post("/users", payload);

  return response.data;
}

export async function update(id, payload) {
  const response = await Vue.prototype.$http.put("/users/" + id, payload);

  return response.data;
}

export async function updateRole(id, role_id) {
  const response = await Vue.prototype.$http.put("/users/" + id + "/role", {
    role_id
  });

  return response.data;
}

export async function updateStatus(id, is_active) {
  const response = await Vue.prototype.$http.put("/users/" + id + "/status", {
    is_active
  });

  return response.data;
}

export async function destroy(id) {
  const response = await Vue.prototype.$http.delete("/users/" + id);

  return response;
}
